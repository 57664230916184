const STATE = {
  created: 0,
  initialized: 1,
  playing: 2,
  destroyed: 3
}

export default (() => {
  class VideoController {
    constructor({ wrapperElem, maxPlayerWaitingTime = 35000, onDestroyCb = () => {} }) {
      this.state = STATE.created
      this.wrapperElem = wrapperElem
      this.maxPlayerWaitingTime = maxPlayerWaitingTime // // 35 seconds (Fallback to bring user to the game, just in case there was an issue with the ad playing)
      this.onDestroyCb = onDestroyCb
      if (!this.wrapperElem) {
        console.error('No Video Wrapper Found')
        return
      }
      const initalSubmoduleMarkup = this.wrapperElem.firstChild
      if (!initalSubmoduleMarkup) {
        console.error('No Video Module Found')
        return
      }
      this.template = initalSubmoduleMarkup.cloneNode(true)
      // We remove the initial  markup because we want to control creating and destroying it on demand based on when an add should appear
      this.wrapperElem.removeChild(initalSubmoduleMarkup)
      this.playerSubmodule = null
      this.videoPlayerInstance = null
      this.videoAdTimeout = null
    }

    init() {
      window.wafer.on('video:ready', data => {
        const { meta: { instance } = {} } = data
        this.videoPlayerInstance = instance
      })

      window.wafer.on('video:adStarted', () => {
        if (this.videoAdTimeout) {
          clearTimeout(this.videoAdTimeout)
          this.videoAdTimeout = null
        }
      })
      window.wafer.on('video:playbackstart', () => this.destroy())
      window.wafer.on('video:adEnded', () => this.destroy())
      window.wafer.on('video:playerError', () => this.destroy())

      this.state = STATE.initialized
    }

    playAd() {
      if (this.state === STATE.created || this.state === STATE.playing) return
      this.videoAdTimeout = setTimeout(() => {
        this.destroy()
      }, this.maxPlayerWaitingTime)
      this.playerSubmodule = this._createPlayerSubmodule()
      this.wrapperElem.appendChild(this.playerSubmodule)
      wafer.base.sync(this.wrapperElem)
      this.state = STATE.playing
    }

    destroy() {
      if (this.state !== STATE.playing) return
      if (this.videoPlayerInstance) {
        this.videoPlayerInstance.pause()
        this.videoPlayerInstance.destroy()
      }
      if (this.videoAdTimeout) {
        clearTimeout(this.videoAdTimeout)
        this.videoAdTimeout = null
      }
      wafer.base.destroy(this.playerSubmodule)
      this.wrapperElem.removeChild(this.playerSubmodule)
      this.playerSubmodule = null
      this.state = STATE.destroyed
      if (typeof this.onDestroyCb === 'function') {
        this.onDestroyCb()
      }
    }

    _createPlayerSubmodule() {
      const playerSubmodule = this.template.cloneNode(true)
      const videoElm = playerSubmodule.querySelector('.m-video-wafer__player')
      videoElm.classList.add('wafer-video')
      return playerSubmodule
    }
  }
  return VideoController
})()
